import HomeAboutImage from "assets/images/Group-89.png";
import Group106 from "assets/images/Group-106.png";
import Group107 from "assets/images/Group-107.png";
import Group108 from "assets/images/Group-108.png";
import Group109 from "assets/images/Group-109.png";
import WhyAreWe from "assets/images/Why-Are-We-Different-From-Others (1).svg";
import MobileImage from "assets/images/Group 150.svg";
export const HomeData = {
  heading: "About Us",
  content:
    "MS BLUE main objective is to raise standards across the industry, we serve H&S legal compliance, Safety Audits, Training and Quality Systems",
  buttonText: "Contact Us",
  image: HomeAboutImage,
  mobileImage:MobileImage,
  contentImage:WhyAreWe,
  CourseDetail: [
    {
      image: Group106,
      heading: "CILT accredited Transport Manager CPC",
      content:
        "The Certificate of Professional Competence (CPC) for Transport Managers in Road Haulage is offered by CILT(UK) on behalf of the Department for Transport and includes a range of subjects related to the business of road transport",
    },
    {
      image: Group107,
      heading: "IRTEC Accredited HGV Inspection Technician Training",
      content:
        "The course is designed to train and prepare HGV inspection technician to a standard where they can undertake the IRTEC HGV inspection technician assessment and gain the accredited 5-year IRTEC licence for the inspection of HGV vehicles. Along with knowledge of the good vehicle operators commitments to road safety.",
    },
    {
      image: Group108,
      heading: "Transport Manager CPC Refresher Training",
      content:
        "With the ever-changing legislation within the transport industry, the Traffic Commissioners strongly recommend that all Transport Managers undertake refresher training from time to time",
    },
    {
      image: Group109,
      heading: "Periodic Driver CPC Training",
      content:
        "Required by drivers who passed their practical driving test before September 2009 (HGV) or 2008 (PCV), or who have previously held a CPC entitlement for the relevant driving licence category. You can now book our Driver CPC training courses online.",
    },
  ],
};
