import React from "react";

export const Text = ({
    color,
    font,
    fontFamily,
    fontSize,
    fontWeight,
    label,
    className,
    lineHeight,
    marginTop,
    marginBottom,
}) => {
    return (
        <p
            className={className}
            style={{
                lineHeight:lineHeight,
                color: color,
                font: font,
                fontFamily: fontFamily,
                fontSize: fontSize,
                fontWeight: fontWeight,
                marginBottom:marginBottom,
                marginTop:marginTop,
            }}
        >
            {label}
        </p>
    );
};
