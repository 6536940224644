import { BrowserRouter, Route, Routes } from "react-router-dom";
import "App.css";
import { routes } from "Constants/Routes";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "components/Footer/footer";
import "bootstrap-icons/font/bootstrap-icons.css";
import { images } from "assets/assets";
import Consultancy from "pages/consultancy";
import Training from "pages/training";
import Home from "pages/Home";
import Contact from "pages/contact";
import "typeface-roboto";
import FeedbackForm from "pages/FeedBackForm";
import ScrollToTopOnNavigate from "components/ScrollToTop";
import PageNotFound from "components/404";
import TrainingForm from "pages/TrainingForm";
import CoursePage from "pages/CoursePage";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTopOnNavigate />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="consultancy" element={<Consultancy />} />
          <Route path="trainings-form" element={<TrainingForm />} />
          <Route path="feedback" element={<FeedbackForm />} />
          <Route path="/training" element={<Training />}>
            <Route path=":item" element={<CoursePage />} />
          </Route>
          <Route path="*" element={<PageNotFound title="404" />} />
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}

export default App;
