import axios from "axios";


const Send_Email = async (data) => {
  const response = {
    url: `https://downloader.techvezoto.online/api/send-email`,
    method: "POST",
    data: JSON.stringify(data),
    headers:{
      'Content-Type': 'application/json'
    }
  };
  return await axios.request(response);
};

export default Send_Email;
