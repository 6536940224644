import InputWithTitle from "components/Inputs/InputsWithTitle";
import React, { useState } from "react";
import "pages/Home/index.css";
import { SimpleButton } from "components/Buttons/SimpleButton";
import ModalStatus from "components/Modals";
import Send_Email from "networking/networking";
import Header from "components/Header/header";
import { validateEmail } from "utils/method";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [contactUs, setContactUs] = useState({
    name: "",
    phone_number: "",
    email_address: "",
    help: "",
  });

  const [isEnable, setIsEnable] = useState({
    error: false,
    isOpenModal: false,
    spinner: false,
  });

  const onChangeText = (val, item) => {
    setContactUs({ ...contactUs, [item]: val });
  };

  const onSubmit = async () => {
    if (
      contactUs?.name === "" ||
      contactUs?.phone_number === "" ||
      contactUs?.email_address === "" ||
      contactUs?.help === "" ||
      validateEmail(contactUs?.email_address)
    ) {
      setIsEnable({ ...isEnable, error: true });
    } else {
      const payload = { ...contactUs, status: 6 };
      console.log(payload);
      try {
        setIsEnable({ ...isEnable, error: false, spinner: true });
        await Send_Email(payload);
        setContactUs((prevValues) => ({
          ...prevValues,
          name: "",
          phone_number: "",
          email_address: "",
          help: "",
        }));
        setIsEnable((prevValues) => ({
          ...prevValues,
          spinner: false,
          isOpenModal: true,
        }));
      } catch {
        setIsEnable({ ...isEnable, spinner: false });
      }
    }
  };

  const capitalizeFirstLetterOfEachWord = (str) => {
    return str
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <>
    <Helmet>
        <title>Contact</title>
      </Helmet>
      <Header />

      <div className="container">
        <div className="row">
          <div
            className="col-md-6 px-3 border-bg-sky"
            style={{ background: "#67D3D1" }}
          >
            <div className="py-5" style={{ position: "relative" }}>
              {Object.keys(contactUs).map((item) => (
                <InputWithTitle
                  key={item}
                  placeHolder={`Your ${capitalizeFirstLetterOfEachWord(item)}`}
                  width={"100%"}
                  value={contactUs[item]}
                  height={item === "help" ? "200px" : "auto"}
                  inputClass={"px-3 py-3 contactfoem_input"}
                  isErrorShow={
                    (contactUs[item] === "" && isEnable?.error) ||
                    (item === "email_address" &&
                      contactUs?.email_address !== "" &&
                      validateEmail(contactUs?.email_address))
                  }
                  errorColor={"red-color"}
                  errorMesg={
                    item === "email_address" &&
                    contactUs?.email_address !== "" &&
                    validateEmail(contactUs?.email_address)
                      ? "Please Enter Verified Email"
                      : "Please Enter This Field"
                  }
                  onChange={(val) => {
                    onChangeText(val, item);
                  }}
                />
              ))}
              <div className="d-flex justify-content-end mt-4 px-3">
                <SimpleButton
                  label={"Submit"}
                  buttonClass={"purple-button"}
                  isSpinner={isEnable?.spinner}
                  onSubmit={onSubmit}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-contact-resp">
            <div className="px-5">
              <h2 style={{ color: "#3a47bc" }} className="mb-text">
                Contact
              </h2>
              <h1 style={{ color: "#88d5d3" }}>Get In Touch</h1>
              <p>
                The Certificate of Professional Competence (CPC) for Transport
                Managers in Road Haulage is offered by CILT(UK) on behalf of the
                Department for Transport and includes a range of subjects
                related to the business of road transport.
              </p>
            </div>
          </div>
        </div>
        <ModalStatus
          isOpenModal={isEnable?.isOpenModal}
          setIsOpenModal={() =>
            setIsEnable({ ...isEnable, isOpenModal: false })
          }
        />
      </div>
    </>
  );
};

export default Contact;
