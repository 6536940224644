import Group137 from "assets/images/Group-137.png";
import Group138 from "assets/images/Group-138.png";
import Group139 from "assets/images/Group-139.png";
import Group140 from "assets/images/Group-140.png";
import Group141 from "assets/images/Group-141.png";
import Img7 from "assets/images/Group-69-1.png";
import Img5 from "assets/images/Group-71.png";
import Img6 from "assets/images/Health-and-Safety-Management (1).png";
import Img2 from "assets/images/Rectangle-10-1.png";
import Img3 from "assets/images/Rectangle-10-2.png";
import Img4 from "assets/images/Rectangle-10-3.png";
import Img1 from "assets/images/Rectangle-10.png";
export const ConsultancyData = {
  title: "Transport Management",
  content: "Our team of experts offer a whole host of courses and support.",
  TransportDetail: [
    {
      title: "Transport Management Consultancy",
      image:Img1,
      content:
        "We provide an all in one 3PL solution for Import/Export warehousing and distribution. A partnership with TMC can provide your business with a reliable logistics",
    },
    {
      title:'Transport Manager\nSupport',
      image:Img2,
      content:
        "Our public inquiries team can advise on formal and informal requests and how to ensure your evidence is clear and persuasive. Protecting your interests.",
    },
    {
      title: "Public Inquiries Representation",
      image:Img3,
      content:
        "We provide an all in one 3PL solution for Import/Export warehousing and distribution. A partnership with TMC can provide your business with a reliable logistics",
    },
    {
      title: "Independent Audits\nSupport",
      image:Img4,
      content:
        "An independent audit is an examination of the financial records, accounts, business transactions, accounting practices, and internal controls of a charitable nonprofit by an “independent” auditor.",
    },
  ],

  ConsultancyDetail: [
    {
      title: "Consultancy",
      image:Group137,
      content:
        "We will act as your Competent or Appointed Person for all your organizational Health and Safety",
    },
    {
      title: "COSHH",
      image:Group138,

      content:
        "We also specialises in COSHH health and safety, handling and awareness. From COSHH RAs to COSHH registers",
    },
    {
      title: "Training",
      image:Group139,

      content:
        "Training We are EUSR (Energy and Utilities Safety Register) approved trainers in CSCS",
    },
    {
      title: "Safety Audits",
      image:Group140,

      content:
        "We, at THS+ are here to help. Whether you require an audit to satisfy a client, an auditing body or you simply want to put your mind at ease.",
    },
    {
      title: "Quality Systems",
      image:Group141,
      content:
        "Our technical expertise in quality management and our know-how of ISO standards such as 9001 and 17025 equip us to assist you with the implementation and optimization of your industrial processes and certification of your products and services.",
    },
  ],
  What_Offer_Image: Img5,
  Waht_Offer_Text: "What We Offer",
  Health_Management: "Health and Safety Management",
  Health_Image: Img6,

  future_image: Img7,
  future: "Your Future’s Partner",
  future_content:
    "With a combined experience of over 45 years in QHSE and Transport management at the highest levels and responsible for millions of pounds in budgets, our team have decided to start THS Plus.Our main objective is to raise standards across the industries we serve through proactive normative regulation, advice and auditing. We offer bespoke packages for all aspects of QHSE and Transport management or turn-key solutions designed to comply with the latest ISO standards.",
};
