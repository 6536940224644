export const formData = {
  generalFeedback: {
    company: {
      label: "Company Name",
      placeholder: "Enter Company Name",
      required: false,
      type:'text',
    },
    tutor: {
      label: "Tutor Name",
      placeholder: "Enter Tutor Name",
      required: false,
      type:'text',

    },
    course: {
      label: "Course Title",
      placeholder: "Enter Course Title",
      required: false,
      type:'text',

    },
    date: {
      label: "Date",
      placeholder: "Enter Date",
      required: false,
      type:'date',

    },
    plan: {
      label: "Please explain how you'd like this feature to work",
      placeholder: "Enter Plan",
      required: false,
      type:'text'
    },
  },
  feedbackQuestions: [
    {
      text: "Were course notes/handouts useful and well presented?",
      options: ["Yes", "No"],
      category: "single",
    },
    {
      text: "Was there enough time for delegate participation?",
      options: ["Yes", "No"],
      category: "single",
    },
    {
      text: "Were the stated course objectives met?",
      options: ["Yes", "No"],
      category: "single",
    },
    {
      text: "Were your joining instructions clear?",
      options: ["Yes", "No"],
      category: "single",
    },
    {
      text: "How have you found your training? Please circle the numbers below.",
      subTitles: ["Informative", "Excellent", "Well Presented"],
      options: ["Poor", "Below Expectations", "Satisfactory", "Good"],
      category: "double",
    },
    {
      text: "Can we improve our booking service?",
      options: ["Yes", "No"],
      category: "single",
    },
  ],
};
