import React from "react";
import Spinner from 'react-bootstrap/Spinner';
export const ButtonsWithIcon = ({ label, icons, buttonClass }) => {
  return (
    <div>
      <button className={buttonClass}>
        {label}
        <span className="mx-2">{icons}</span>
      </button>
    </div>
  );
};
