import React from "react";
const RadioButton = ({ value, checked, onChange, label,fontWeight }) => {
  return (
    <div className="d-flex flex-row align-items-center">
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={(val) => onChange(val.target.checked)}
      />
      <p className="mb-0 my-2 mx-3" style={{fontWeight:fontWeight}}>{label}</p>
    </div>
  );
};

export default RadioButton;
