import InputWithTitle from "components/Inputs/InputsWithTitle";
import "components/style.css";
import { useState } from "react";
import { forwardRef, useImperativeHandle } from "react";
const Dropdownwithtitle = ({
  label,
  className,
  labelClass,
  containerWidth,
  Data,
  isSearchBarShow,
  value,
  onDropdownOpen,
  onSearchCourse,
  isShowDropdown,
  inputValue,
  isErrorShow,
  errorMesg,
  errorColor,
}) => {
  const [dropdownHandle, setIsDropdownHandle] = useState(false);
  return (
    <div
      className={"w-100 mx-2" || className}
      style={{
        width: containerWidth,
      }}
    >
      <p style={{fontWeight:500}} className={labelClass}>{label}</p>
      <div
        className="py-3"
        style={{
          borderRadius: "5px",
          border: "1px solid #DADBDD",
          fontSize: "11px",
        }}
      >
        <div
          className="d-flex justify-content-between"
          onClick={() => onDropdownOpen()}
        >
          <p
            className="mb-0"
            style={{
              fontWeight: "400",
              marginLeft:'10px'
            }}
          >
            {value || '-Select-'}
          </p>
          <span className="mx-2" style={{ height: "auto" }}>
            <i class="bi bi-caret-down-fill"></i>
          </span>
        </div>
        {isShowDropdown && (
          <div
            className="dropdown-container"
            style={{ width: "100%", height: "auto" }}
          >
            {isSearchBarShow && (
              <InputWithTitle
              className={'mx-3'}
              value={inputValue}
                width={"100%"}
                height={'40px'}
                placeHolder={'Enter Search'}
                padding={"25px 10px"}
                onChange={onSearchCourse}
              />
            )}

            {Data.map((item, index) => (
              <label
                key={index}
                className="option"
                onClick={() => onDropdownOpen(item)}
              >
                {item}
              </label>
            ))}
          </div>
        )}
      </div>
      {isErrorShow && <p className={errorColor}>{errorMesg}</p>}
    </div>
  );
};

export default Dropdownwithtitle;
