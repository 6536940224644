import React from "react";
import Modal from "react-bootstrap/Modal";

import Lottie from "react-lottie";

const ModalStatus = ({ isOpenModal, setIsOpenModal,LottieFile,message }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:LottieFile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Modal show={isOpenModal} style={{ position: "fixed", top: "30%" }}>
      <Modal.Header className="d-flex justify-content-end">
        <span
          style={{ fontSize: "20px", cursor: "pointer" }}
          onClick={setIsOpenModal}
        >
          <i className="bi bi-x-lg"></i>
        </span>
      </Modal.Header>
      <Modal.Dialog>
        <p style={{ color: "#3A47BC", fontSize: 30, padding: "10px" }}>
          {message}
        </p>
        <Lottie options={defaultOptions} height={150} width={150} />
      </Modal.Dialog>
    </Modal>
  );
};

export default ModalStatus;
