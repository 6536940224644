import React from "react";
import Spinner from "react-bootstrap/Spinner";
export const SimpleButton = ({ label, icons, buttonClass, isSpinner,onSubmit }) => {
  return (
    <div>
      <button className={buttonClass} onClick={onSubmit}>
        {isSpinner ? <Spinner animation="grow" variant="light" /> :  label }
      </button>
    </div>
  );
};
