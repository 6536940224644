export const Form_Fields = {
  first_name: "",
  middle_name: "",
  last_name: "",
  company_name: "",
  email: "",
  phone_number: "",
};

export const CoursesName = [
  "Operator License Awareness Training",
  "Transport Manager CPC Refresher Training",
  "Periodic Driver CPC Training",
  "IRTEC Accredited HGV Inspection Technician Training",
  "CILT accredited Transport Manager CPC",
];

export const CourseDates = [

  "06 August 2024",
  "08 October 2024",
  "03 December 2024",
];