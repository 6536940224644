import React from "react";

const Table = () => {
  return (
    <table>
      <tr>
        <th>Special needs</th>
        <th>Code</th>
      </tr>
      <tr>
        <td>Hearing</td>
        <td>S</td>
      </tr>
      <tr>
        <td>Learning</td>
        <td>H</td>
      </tr>
      <tr>
        <td>Mobility</td>
        <td>L</td>
      </tr>
      <tr>
        <td>Other</td>
        <td>M</td>
      </tr>
      <tr>
        <td>Candidate prefers no statement</td>
        <td>S</td>
      </tr>
      <tr>
        <td>Sight</td>
        <td>O</td>
      </tr>
     
     
    
    
    </table>
  );
};

export default Table;
