import React from "react";

const InputWithTitle = React.forwardRef(
  (
    {
      placeHolder,
      label,
      className,
      inputClass,
      labelClass,
      width,
      height,
      onChange,
      containerWidth,
      errorColor,
      isErrorShow,
      borderColor,
      errorMesg,
      value,
      type,
    },
    ref
  ) => {
    return (
      <div className={className} style={{ width: containerWidth }} ref={ref}>
        <p className={labelClass}>{label}</p>
        <input
          value={value}
          style={{
            width: width,
            height: height,
            borderColor: borderColor,
          }}
          type={type}
          placeholder={placeHolder}
          className={inputClass}
          onChange={(e) => onChange(e.target.value)}
        />
        {isErrorShow && <p className={errorColor}>{errorMesg}</p>}
      </div>
    );
  }
);

export default InputWithTitle;
